<template>
    <div>
        <div class="search-box">
            <a class="home-btn" href="#/team">返回</a>
            <el-input placeholder="输入ID/昵称搜索" v-model="kw" class="input-with-select" :clearable=true>
                <el-button slot="append" icon="el-icon-search" @click.stop="getData(1)"></el-button>
            </el-input>
        </div>
        <el-empty v-if="list.length == 0" description="空空如也~"></el-empty>
        <ul class="user-ul" v-else @scroll="scrollToBottom($event)">
            <li class="list" v-for="(m, i) in list" :key="m.id" @click.stop="goUser(m.id)">
                <img :src="rImg(m.avatar)" :onerror="txImg" class="tx" alt="">
                <div class="content">
                    <p class="name">{{m.name}} <span class="role" @click.stop="copyTxt(m.id)">({{m.id}})</span></p>
                    <div class="des">
                        <span>注册时间：{{m.register_datetime}}</span>
                    </div>
                </div>
            </li>
            <p class="more-btn">{{(total==-1 || list.length % 10 !=0 ) ? '暂无更多':'加载中'}}</p>
        </ul>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    export default {
        components: {},
        data() {
            return {
                list: [],
                kw: '',
                total: 0
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getData(1)
        },
        methods: {
            scrollToBottom(event) {
                let e = event.currentTarget;
                if (e.offsetHeight + e.scrollTop >= e.scrollHeight) {
                    this.getData()
                }
            },
            copyTxt(txt) {
                navigator.clipboard.writeText(txt).then(() => {
                    this.$y_msg("复制成功");
                });
            },
            // ...mapMutations([])
            // 获取列表
            async getData(first) {
                let param = {
                    id: this.$y_getKey('userid'),
                    level: 1,
                    pagesize: 10,
                };
                if (this.kw) {
                    param.keyword = this.kw;
                }
                if (first) {
                    param.page = 1;
                }
                let {
                    list,
                    total
                } = await this.$y_list("api/Member/team", this.list, param);
                this.list = list;
                this.total = total;
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    .search-box {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 1;
        background: #fff;
        padding: 10px;
        display: flex;
        align-items: center;

        .home-btn {
            padding-right: 10px;
            word-break: keep-all;
        }
    }

    .user-ul {
        height: 100vh;
        overflow-y: auto;
    }

    .list {
        padding: 15px;
        border-bottom: 1px solid #eee;
        display: flex;

        .tx {
            width: 50px;
            height: 50px;
            margin-right: 20px;
        }

        .name {
            font-size: 18px;
        }

        .role {
            color: #fd5621;
            font-size: 14px;
        }

        .des {
            color: #555;
            margin-top: 7px;

            span {
                &:after {
                    content: '/';
                    display: inline-block;
                    margin: 0 5px;
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    .more-btn {
        text-align: center;
        color: #aaa;
        padding: 15px 0;
    }
</style>